require('core-js/es/promise');
require('core-js/es/object');
require('core-js/es/object/values');
require('core-js/es/object/entries');
require('core-js/es/object/assign');
require('core-js/es/array');
require('core-js/es/array/includes');
require('core-js/es/map');
require('core-js/es/set');
require('intersection-observer');
